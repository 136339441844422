import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EmailService } from '@core/services/email.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';


@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;

  submitted = false;

  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  loading = false;

  constructor(
    private formBuilder: FormBuilder,
    private emailService: EmailService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.contactForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      subject: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      // tslint:disable-next-line:no-magic-numbers
      text: ['', Validators.required],
    });
  }

  get controls() {
    return this.contactForm.controls;
  }

  onSubmit() {
    this.submitted = true;

    if (this.contactForm.invalid) {
      return;
    }

    const { firstName, lastName, email, subject, text } = this.contactForm.value;
    this.loading = true;
    return this.emailService.sendEmail({
      from: `${firstName} ${lastName} <${email}>`,
      subject,
      text,
    }).subscribe(() => {
      this.loading = false;
      return Swal.fire({
        title: this.translateService.instant('Contact.form.results.success.title'),
        icon: 'success',
        confirmButtonText: this.translateService.instant('accept'),
      });
    }, () => {
      this.loading = false;
      return Swal.fire({
        title: this.translateService.instant('Contact.form.results.fail.title'),
        text: this.translateService.instant('Contact.form.results.fail.message'),
        icon: 'error',
        confirmButtonText: this.translateService.instant('accept'),
      });
    });
  }
}
