import { Component, OnInit } from '@angular/core';
import { PageNavigation } from '@shared/constants/navigation.constants';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  readonly links = PageNavigation;

  constructor() { }

  ngOnInit(): void {
  }
}
