<section class="section-padding inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="text-center">Campaign List</h1>
        <p class="text-center"><a href="#">Home</a> / Campaign List</p>
      </div>
    </div>
  </div>
</section>
<section class="section-padding-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="campaign"> <img src="assets/images/post-1.png" class="img-fluid" alt="Post-1">
          <div class="text-cont">
            <h5><a href="#">2019 Halloween Ball Honoree</a> </h5>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
            <div class="row">
              <div class="col-md-12">
                <div class="fund-info"> Raised <br/> <span>$1,202.00</span></div>
                <div class="fund-info"> Goal   <br/> <span>$2,000.00</span></div>
                <div class="fund-info"> Donor  <br/> <span>68</span></div>
              </div>
            </div>
            <a class="btn btn-primary" href="#">View Details</a> <a href="#" class="read-more alignright">150 Days Left</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="campaign"> <img src="assets/images/post-2.png" class="img-fluid" alt="Post-1">
          <div class="text-cont">
            <h5><a href="#">2019 Halloween Ball Honoree</a> </h5>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
            <div class="row">
              <div class="col-md-12">
                <div class="fund-info"> Raised <br/> <span>$1,202.00</span></div>
                <div class="fund-info"> Goal   <br/> <span>$2,000.00</span></div>
                <div class="fund-info"> Donor  <br/> <span>68</span></div>
              </div>
            </div>
            <a class="btn btn-primary" href="#">View Details</a> <a href="#" class="read-more alignright">150 Days Left</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="campaign"> <img src="assets/images/post-3.png" class="img-fluid" alt="Post-1">
          <div class="text-cont">
            <h5><a href="#">2019 Halloween Ball Honoree</a> </h5>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
            <div class="row">
              <div class="col-md-12">
                <div class="fund-info"> Raised <br/> <span>$1,202.00</span></div>
                <div class="fund-info"> Goal   <br/> <span>$2,000.00</span></div>
                <div class="fund-info"> Donor  <br/> <span>68</span></div>
              </div>
            </div>
            <a class="btn btn-primary" href="#">View Details</a> <a href="#" class="read-more alignright">150 Days Left</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="campaign"> <img src="assets/images/post-1.png" class="img-fluid" alt="Post-1">
          <div class="text-cont">
            <h5><a href="#">2019 Halloween Ball Honoree</a> </h5>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
            <div class="row">
              <div class="col-md-12">
                <div class="fund-info"> Raised <br/> <span>$1,202.00</span></div>
                <div class="fund-info"> Goal   <br/> <span>$2,000.00</span></div>
                <div class="fund-info"> Donor  <br/> <span>68</span></div>
              </div>
            </div>
            <a class="btn btn-primary" href="#">View Details</a> <a href="#" class="read-more alignright">150 Days Left</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="campaign"> <img src="assets/images/post-2.png" class="img-fluid" alt="Post-1">
          <div class="text-cont">
            <h5><a href="#">2019 Halloween Ball Honoree</a> </h5>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
            <div class="row">
              <div class="col-md-12">
                <div class="fund-info"> Raised <br/> <span>$1,202.00</span></div>
                <div class="fund-info"> Goal   <br/> <span>$2,000.00</span></div>
                <div class="fund-info"> Donor  <br/> <span>68</span></div>
              </div>
            </div>
            <a class="btn btn-primary" href="#">View Details</a> <a href="#" class="read-more alignright">150 Days Left</a>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="campaign"> <img src="assets/images/post-3.png" class="img-fluid" alt="Post-1">
          <div class="text-cont">
            <h5><a href="#">2019 Halloween Ball Honoree</a> </h5>
            <p>Contrary to popular belief, Lorem Ipsum is not simply random text.</p>
            <div class="row">
              <div class="col-md-12">
                <div class="fund-info"> Raised <br/> <span>$1,202.00</span></div>
                <div class="fund-info"> Goal   <br/> <span>$2,000.00</span></div>
                <div class="fund-info"> Donor  <br/> <span>68</span></div>
              </div>
            </div>
            <a class="btn btn-primary" href="#">View Details</a> <a href="#" class="read-more alignright">150 Days Left</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<section class="subscribe">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6">
        <div class="icon-cont"><i class="icofont-envelope-open"></i></div>
        <div class="text-cont">
          <h3>Join our mailing List</h3>
          <h6>Subscribe us for latest news</h6>
        </div>
      </div>
      <div class="col-lg-8 col-md-6">
        <div class="subscribe-cont">
          <form action="#" method="post">
            <input type="text" class="form-control" name="fname" placeholder="Subscribe Your Mail ID">
            <button type="submit"><i class="icofont-long-arrow-right"></i></button>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>