import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-rating',
  templateUrl: './rating.component.html',
  styleUrls: ['./rating.component.css'],
})
export class RatingComponent implements OnInit {
  @Input() private rating: number;

  constructor() { }

  ngOnInit(): void {
  }

  isChecked(pos: number): boolean {
    return pos <= this.rating;
  }
}
