import { NavigationModel } from '@shared/models/navigation.model';

/**
 * Used to generalize page navigations
 */
export const PageNavigation: Array<NavigationModel> = [
  new NavigationModel('Navbar.home', '/'),
  new NavigationModel('Navbar.about', '/about'),
  new NavigationModel('Navbar.providers', '/providers'),
  new NavigationModel('Navbar.blog', '/blogger'),
  new NavigationModel('Navbar.contact', '/contact'),
];
