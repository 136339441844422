import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-listing',
  templateUrl: './campaign-listing.component.html',
  styleUrls: ['./campaign-listing.component.css'],
})
export class CampaignListingComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
