<section class="subscribe">
    <div class="container">
    </div>
</section>
<footer class="footer">
    <div class="footer-top">
        <div class="container">
            <div class="row">
                <div class="col-md-4">
                    <div class="item">
                        <h6>{{'Footer.ourSocialNetworks' | translate}}</h6>
                        <p>{{'Footer.socialNetworksDesc' | translate}}</p>
                    </div>
                    <div class="item">
                        <ul class="social">
                            <li><a [href]="'Social.facebook' | translate"><i class="icofont-facebook"></i></a></li>
                            <li><a href="#"><i class="icofont-instagram"></i></a></li>
                            <li><a href="#"><i class="icofont-linkedin"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item">
                        <h6>{{'Footer.links' | translate}}</h6>
                        <ul class="links">
                            <li *ngFor="let link of links">
                                <a [routerLink]="link.link">{{link.translateTitle | translate}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="item">
                        <h6>{{'Footer.contactInfo' | translate}}</h6>
                        <p>
                            <i class="icofont-google-map"></i>
                            <a [href]="'http://maps.google.com/?q='+ ('ContactInfo.address' | translate)">
                                {{'ContactInfo.address' | translate}}
                            </a>
                        </p>
                        <p>
                            <i class="icofont-clock-time"></i>
                            {{'ContactInfo.timeTable' | translate}}
                        </p>
                        <p>
                            <i class="icofont-phone"></i>
                            <a [href]="'tel:'+ ('ContactInfo.phone' | translate)"> {{'ContactInfo.phone' | translate}}
                            </a>
                        </p>
                        <p>
                            <i class="icofont-mobile-phone"></i>
                            <a [href]="'tel:'+ ('ContactInfo.mobile' | translate)"> {{'ContactInfo.mobile' | translate}}
                            </a>
                        </p>
                        <p>
                            <i class="icofont-envelope"></i>
                            <a [href]="'mailto:'+('ContactInfo.email' | translate)"> {{'ContactInfo.email' | translate}}
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
