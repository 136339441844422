<section class="section-padding inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="text-center">{{'Blog.title' | translate}}</h1>
      </div>
    </div>
  </div>
</section>
<section class="section-padding-gray blog-posts">
  <div class="container">
    <div class="row">
      <div class="col-md-4">
      	<div class="sidebar">
        	<div class="item">
            	<h6>{{'Blog.searchTitle' | translate}}</h6>
        		<input type="text" value="" name="s" [(ngModel)]="searchText" [placeholder]="'Blog.search' | translate">
            </div>
        </div>
      </div>
      <div class="col-md-8">
      	<div class="row">
      	<div class="col-md-12" (click)="onRowClick(post)" *ngFor="let post of posts | appFilter:searchText:'title' | paginate: { itemsPerPage: 5, currentPage: page } ">
        <div class="post">
            <img [src]="post.previewImage" class="img-fluid" alt="Post-1">
          <div class="text-cont">
            <h5>{{post.title}}</h5>
            <h6>{{post.date | date}}</h6>
            <p [innerHTML]="post.contentPreview"></p>
            <a class="read-more">{{'Blog.readMore' | translate}} <i class="icofont-long-arrow-right"></i></a> </div>
        </div>
        </div>


            <pagination-controls (pageChange)="page = $event"></pagination-controls>
        </div>


      </div>


    </div>
  </div>
</section>
<ngx-loading [show]="loading" [config]="{ animationType: ngxLoadingAnimationTypes.doubleBounce,
        backdropBackgroundColour: 'rgba(0,0,0,0.3)', backdropBorderRadius: '10px',
        primaryColour: '#1E3F5A', secondaryColour: '#E9B335', tertiaryColour: '#1E3F5A' }"></ngx-loading>
