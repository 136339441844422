// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  language: 'es',
  commentsUri:'https://ahne3igozl.execute-api.eu-west-1.amazonaws.com/production/comments',
  firebase: {
    apiKey: 'AIzaSyAlNMeO1PZDe3GVZQQy_IlsaA5Ay5-clis',
    authDomain: 'la-boutique-industrial-web.firebaseapp.com',
    databaseURL: 'https://la-boutique-industrial-web.firebaseio.com',
    projectId: 'la-boutique-industrial-web',
    storageBucket: 'la-boutique-industrial-web.appspot.com',
    messagingSenderId: '709931398740',
    appId: '1:709931398740:web:da8d11323dfd90a3a79fc1',
    measurementId: 'G-CQJY3Q5HM9',
  },
  Contact: {
    sendEmail: 'http://localhost:3000/offline/email',
  },
  Blog: {
    POST_BASE_API: 'https://laboutiqueindustrial.com/blog/wp-json/wp/v2/posts',
    FIELDS: '_embed',
    storageKey : 'post',
  },
  Collections: {
    providers: 'providers',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
