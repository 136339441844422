import { BrowserModule } from '@angular/platform-browser';
import { Injector, NgModule, APP_INITIALIZER } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TopbarComponent } from '@layouts/topbar/topbar.component';
import { FooterComponent } from '@layouts/footer/footer.component';
import { HomeComponent } from '@components/home/home.component';
import { AboutComponent } from '@components/about/about.component';
import { CampaignListingComponent } from '@components/campaign-listing/campaign-listing.component';
import { CampaignDetailsComponent } from '@components/campaign-details/campaign-details.component';
import { ProvidersComponent } from '@components/providers/providers.component';
import { EventsDetailsComponent } from '@components/events-details/events-details.component';
import { BlogListingComponent } from '@components/blog-listing/blog-listing.component';
import { BlogDetailsComponent } from '@components/blog-details/blog-details.component';
import { ContactComponent } from '@components/contact/contact.component';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { environment } from '@environments/environment';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { OurServicesComponent } from '@layouts/our-services/our-services.component';
import { ClientCommentsComponent } from '@layouts/client-comments/client-comments.component';
import { HomeCarouselComponent } from '@layouts/home-carousel/home-carousel.component';
import { RatingComponent } from '@layouts/rating/rating.component';
import { AngularFireModule } from '@angular/fire';
import { ShortByPipe } from '@core/pipe/orderBy.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipe } from '@core/pipe/filter.pipe';
import { NgxLoadingModule } from 'ngx-loading';
import { LOCATION_INITIALIZED } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    TopbarComponent,
    FooterComponent,
    HomeComponent,
    AboutComponent,
    CampaignListingComponent,
    CampaignDetailsComponent,
    ProvidersComponent,
    EventsDetailsComponent,
    BlogListingComponent,
    BlogDetailsComponent,
    ContactComponent,
    OurServicesComponent,
    ClientCommentsComponent,
    HomeCarouselComponent,
    RatingComponent,
    ShortByPipe,
    FilterPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    TranslateModule.forRoot({
      defaultLanguage: environment.language,
      loader: {
        provide: TranslateLoader,
        // tslint:disable-next-line:ter-arrow-body-style
        useFactory: (createTranslateLoader),
        deps: [HttpClient],
      },
    }),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    ReactiveFormsModule,
    SweetAlert2Module.forRoot(),
    NgxPaginationModule,
    NgxLoadingModule.forRoot({}),
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService, Injector],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}


export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function appInitializerFactory(translate: TranslateService, injector: Injector) {
  return () => new Promise<any>((resolve: any) => {
    const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
    locationInitialized.then(() => {
      const langToSet = 'es';
      translate.setDefaultLang('es');
      translate.use(langToSet).subscribe(() => {
      }, () => {
        console.error(`Problem with '${langToSet}' language initialization.'`);
      }, () => {
        resolve(null);
      });
    });
  });
}
