<section class="section-padding inner-header">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <h1 class="text-center">{{'Contact.title' | translate}}</h1>
            </div>
        </div>
    </div>
</section>
<section class="section-padding contact">
    <div class="container">
        <div class="row">
            <div class="col-md-8">
                <h3>{{'Contact.subtitle' | translate}}</h3>
                <form [formGroup]="contactForm" (ngSubmit)="onSubmit()">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text"
                                       class="form-control"
                                       formControlName="firstName"
                                       [class.is-invalid]="submitted && controls.firstName.errors"
                                       [placeholder]="'Contact.form.firstName' | translate">
                                <div *ngIf="submitted && controls.firstName.errors" class="invalid-feedback">
                                    <div *ngIf="controls.firstName.errors.required">
                                        {{'Contact.form.errors.firstName' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <input type="text"
                                       class="form-control"
                                       formControlName="lastName"
                                       [class.is-invalid]="submitted && controls.lastName.errors"
                                       [placeholder]="'Contact.form.lastName' | translate">
                                <div *ngIf="submitted && controls.lastName.errors" class="invalid-feedback">
                                    <div *ngIf="controls.lastName.errors.required">
                                        {{'Contact.form.errors.lastName' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="email"
                                       class="form-control"
                                       name="email"
                                       formControlName="email"
                                       [class.is-invalid]="submitted && controls.email.errors"
                                       [placeholder]="'Contact.form.email' | translate">
                                <div *ngIf="submitted && controls.email.errors" class="invalid-feedback">
                                    <div *ngIf="controls.email.errors.required">
                                        {{'Contact.form.errors.email' | translate}}
                                    </div>
                                    <div *ngIf="controls.email.errors.email">
                                        {{'Contact.form.errors.emailInvalid' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <input type="email"
                                       class="form-control"
                                       name="subject"
                                       formControlName="subject"
                                       [class.is-invalid]="submitted && controls.subject.errors"
                                       [placeholder]="'Contact.form.subject' | translate">
                                <div *ngIf="submitted && controls.subject.errors" class="invalid-feedback">
                                    <div *ngIf="controls.subject.errors.required">
                                        {{'Contact.form.errors.subject' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <textarea rows="10"
                                          class="form-control"
                                          name="comment"
                                          formControlName="text"
                                          [class.is-invalid]="submitted && controls.text.errors"
                                          [placeholder]="'Contact.form.message' | translate"
                                          style="height:150px"></textarea>
                                <div *ngIf="submitted && controls.text.errors" class="invalid-feedback">
                                    <div *ngIf="controls.text.errors.required">
                                        {{'Contact.form.errors.text' | translate}}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <button type="submit" class="btn btn-default btn-lg wow tada">{{'Contact.form.send' | translate}}</button>
                        </div>
                    </div>
                </form>
            </div>
            <div class="col-md-4">
                <div class="contact-info">
                    <div class="item">
                        <div class="icon-cont"><i class="icofont-google-map"></i></div>
                        <div class="text-cont">
                            <h6>{{'Contact.address' | translate}}</h6>
                            <h5><a [href]="'http://maps.google.com/?q='+ ('ContactInfo.address' | translate)">
                                {{'ContactInfo.address' | translate}}
                            </a></h5>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon-cont"><i class="icofont-phone"></i></div>
                        <div class="text-cont">
                            <h6>{{'Contact.phone' | translate }}</h6>
                            <h5><a [href]="'tel:'+ ('ContactInfo.phone' | translate)">
                                {{'ContactInfo.phone' | translate }}
                            </a></h5>
                        </div>
                    </div>
                    <div class="item">
                        <div class="icon-cont"><i class="icofont-mobile-phone"></i></div>
                        <div class="text-cont">
                            <h6>{{'Contact.mobile' | translate }}</h6>
                            <h5><a [href]="'tel:'+ ('ContactInfo.mobile' | translate)">
                                {{'ContactInfo.mobile' | translate }}
                            </a></h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<ngx-loading [show]="loading" [config]="{ animationType: ngxLoadingAnimationTypes.doubleBounce,
        backdropBackgroundColour: 'rgba(0,0,0,0.3)', backdropBorderRadius: '10px',
        primaryColour: '#1E3F5A', secondaryColour: '#E9B335', tertiaryColour: '#1E3F5A' }"></ngx-loading>
