<section class="section-padding inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="text-center">{{'Navbar.about' | translate}}</h1>
      </div>
    </div>
  </div>
</section>
<section class="section-padding about-3">
  <div class="container">
    <div class="row">
      <div class="col-md-7">
          <h3>{{'About.title' | translate}}</h3>
        <div class="text-cont" [innerHTML]="'About.description' | translate">
        </div>
          <h5>{{'About.ourServices' | translate}}</h5>
          <ul class="features">
            <li *ngFor="let _ of [].constructor(SERVICES_COUNT); let i = index">{{'About.services.'+i | translate}}</li>
          </ul>
      </div>
      <div class="col-md-5">
        <div class="img-cont">
        	<img class="img-fluid" src="../../../../assets/images/logo3.jpg" alt="about 3">
          <div class="box"></div>
         </div>
      </div>
    </div>
  </div>
</section>
<app-client-comments></app-client-comments>