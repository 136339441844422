import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-services',
  templateUrl: './our-services.component.html',
  styleUrls: [],
})
export class OurServicesComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
