<app-home-carousel></app-home-carousel>
<app-our-services></app-our-services>
<section class="section-padding-gray about-2">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <div class="img-cont wow fadeInDown h-90">
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3080.1752252021374!2d-6.380212684235892!3d39.46536997948718!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd15dfc5191e9c49%3A0x4feb5fb3f515443c!2sLa+Boutique+Industrial!5e0!3m2!1ses!2ses!4v1478117437493"
                            class="w-100 h-100" frameborder="0" style="border:0; align-content: center"
                            allowfullscreen></iframe>
                </div>
            </div>
            <div class="col-md-6">
                <div class="text-cont wow fadeInLeft">
                    <h3>{{'Navbar.about' | translate}}</h3>
                    <p>
                        {{'Home.aboutDescription' | translate}}
                    </p>
                    <a class="btn btn-primary btn-lg" routerLink="/about">{{'Home.knowUs' | translate}}</a>
                </div>
            </div>
        </div>
    </div>
</section>
<app-client-comments></app-client-comments>