<section class="section-padding-gray testimonial-1" *ngIf="commentsLoaded">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="section-title-2">
                    <h3 class="text-center" [innerHTML]="'ClientComments.title' | translate"></h3>
                    <p class="text-center">{{'ClientComments.description' | translate}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 owl-carousel owl-theme testimonial">
                <div class="item d-flex align-items-center" *ngFor="let review of reviews">
                    <div class="row">
                        <div class="col-sm-3">
                            <img [src]="review.photo" alt="" class="rounded-circle img-fluid"/>
                        </div>
                        <div class="col-sm-9">
                            <p>{{review.text}}</p>
                            <h6> {{review.author | titlecase}}</h6>
                            <app-rating [rating]="review.rating"></app-rating> - <small>{{review.date | date}}</small>
                        </div>
                    </div>
                </div>
<!--                <div class="item" *ngFor="let review of reviews">-->
<!--                    <div class="col-12 m-2">-->
<!--                        <blockquote>-->
<!--                        {{review.text}}-->
<!--                        </blockquote>-->
<!--                    </div>-->
<!--                    <div class="col-12">-->
<!--                       <h6>{{review.author_name | titlecase}}</h6>-->
<!--                       <app-rating [rating]="review.rating"></app-rating>-->
<!--&lt;!&ndash;                    <h6><small>{{review.rating}} - {{review.time * 1000 | date}}</small></h6>&ndash;&gt;-->
<!--                    </div>-->

<!--                </div>-->
            </div>
        </div>
    </div>
</section>