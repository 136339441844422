<div id="myCarousel" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li data-target="#myCarousel" data-slide-to="0" class="active"></li>
        <li data-target="#myCarousel" data-slide-to="1"></li>
        <li data-target="#myCarousel" data-slide-to="2"></li>
        <li data-target="#myCarousel" data-slide-to="3"></li>
        <li data-target="#myCarousel" data-slide-to="4"></li>
    </ol>
    <div class="carousel-inner">
        <div class="carousel-item active"> <img class="first-slide" src="../../../../assets/images/frontal.jpg" alt="">
        </div>
        <div class="carousel-item"> <img class="first-slide" src="../../../../assets/images/lbi/inside1.png" alt="">
        </div>
        <div class="carousel-item"> <img class="first-slide" src="assets/images/lbi/inside2.png" alt="">
        </div>
        <div class="carousel-item"> <img class="first-slide" src="assets/images/lbi/inside3.png" alt="">
        </div>
        <div class="carousel-item"> <img class="first-slide" src="assets/images/lbi/inside4.png" alt="">
        </div>
    </div>
    <a class="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev"> <i class="icofont-dotted-left"></i> <span class="sr-only">Previous</span> </a>
    <a class="carousel-control-next" href="#myCarousel" role="button" data-slide="next"> <i class="icofont-dotted-right"></i> <span class="sr-only">Next</span> </a> </div>
