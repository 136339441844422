
<nav class="navbar navbar-expand-lg navbar-light btco-hover-menu navbar-1">
    <div class="container mb-3">
        <a class="navbar-brand d-content" routerLink="/">
            <img class="logo" src="assets/images/logo.png" alt="logo">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation"><span
                class="navbar-toggler-icon"></span></button>
        <div class="collapse navbar-collapse" id="navbarNavDropdown">
            <ul class="navbar-nav ml-auto">
                <li *ngFor="let link of links" class="nav-item">
                    <a class="nav-link" [routerLink]="link.link"> {{link.translateTitle | translate}} </a>
                </li>
<!--                <li class="nav-item dropdown">-->
<!--                    <a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"-->
<!--                                                 aria-haspopup="true" aria-expanded="false"> Campaign </a>-->
<!--                    <ul class="dropdown-menu animated fadeInUp">-->
<!--                        <li><a class="dropdown-item" routerLink="/campaign-listing">Campaign Listing</a></li>-->
<!--                        <li><a class="dropdown-item" routerLink="/campaign-details">Campaign Details</a></li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"-->
<!--                                                 aria-haspopup="true" aria-expanded="false"> Events </a>-->
<!--                    <ul class="dropdown-menu animated fadeInUp">-->
<!--                        <li><a class="dropdown-item" routerLink="/providers">Events Listing</a></li>-->
<!--                        <li><a class="dropdown-item" routerLink="/events-details">Events Details</a></li>-->
<!--                    </ul>-->
<!--                </li>-->
<!--                <li class="nav-item dropdown"><a class="nav-link dropdown-toggle" href="#" data-toggle="dropdown"-->
<!--                                                 aria-haspopup="true" aria-expanded="false"> Blog </a>-->
<!--                    <ul class="dropdown-menu animated fadeInUp">-->
<!--                        <li><a class="dropdown-item" routerLink="/blog-listing">Blog Listing</a></li>-->
<!--                        <li><a class="dropdown-item" routerLink="/blog-details">Blog Details</a></li>-->
<!--                    </ul>-->
<!--                </li>-->
            </ul>
        </div>
    </div>
</nav>