import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-campaign-details',
  templateUrl: './campaign-details.component.html',
  styleUrls: ['./campaign-details.component.css'],
})
export class CampaignDetailsComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }
}
