import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProvidersService } from '@core/services/providers.service';
import { Provider } from '@shared/models/provider.model';
import { Subscription } from 'rxjs';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-providers',
  templateUrl: './providers.component.html',
  styleUrls: ['./providers.component.css'],
})
export class ProvidersComponent implements OnInit, OnDestroy {
  providers: Provider[];

  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  loading = false;

  providersSubscription: Subscription;
  constructor(
    private providersService: ProvidersService,
    private translateService: TranslateService,
  ) { }

  ngOnInit(): void {
    this.providersSubscription = this.providersService
      .listProviders().subscribe((data: Provider[]) => {
        this.providers = data;
      }, () => Swal.fire({
        title: this.translateService.instant('Providers.results.fail.title'),
        text: this.translateService.instant('Providers.results.fail.message'),
        icon: 'error',
        confirmButtonText: this.translateService.instant('accept'),
      }), () => (this.loading = false));
  }


  ngOnDestroy() {
    this.providersSubscription.unsubscribe();
  }

  navigateTo(provider: Provider) {
    return window.open(provider.webUrl, '_blank');
  }
}
