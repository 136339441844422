import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Post } from '@shared/models/post.model';
import { environment } from '@environments/environment';
import { isEmpty } from 'lodash';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BlogService } from '@core/services/blog.service';
import { ngxLoadingAnimationTypes } from 'ngx-loading';
declare var $: any;

@Component({
  selector: 'app-blog-details',
  templateUrl: './blog-details.component.html',
  styleUrls: ['./blog-details.component.css'],
})
export class BlogDetailsComponent implements OnInit, AfterViewInit {
  post: Post;
  loading = false;
  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(
    private router: Router,
    private translateService: TranslateService,
    private blogService: BlogService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    try {
      const post = JSON.parse(localStorage.getItem(environment.Blog.storageKey));
      if (!post || isEmpty(post)) {
        const postId = this.route.snapshot.params.postId;
        if (!postId || isEmpty(postId)) {
          throw new Error();
        }
        this.loading = true;
        this.blogService.getPosts(Number(postId)).subscribe((data: Post) => {
          this.post = data;
        }, () => Swal.fire({
          title: this.translateService.instant('Blog.results.fail.title'),
          text: this.translateService.instant('Blog.results.fail.message'),
          icon: 'error',
          confirmButtonText: this.translateService.instant('accept'),
        }), () => (this.loading = false));
      } else {
        this.post = post;
      }
    } catch (e) {
      Swal.fire({
        title: this.translateService.instant('Blog.detail.results.fail.title'),
        text: this.translateService.instant('Blog.detail.results.fail.message'),
        icon: 'error',
        confirmButtonText: this.translateService.instant('accept'),
      }).then(() => this.router.navigate(['blogger']));
    }
  }

  ngAfterViewInit(): void {
    $('body > app-root > app-blog-details > section.section-padding-gray.blog-posts > div > div > div > div > div > div > div > div > figure > img').attr('width', '100%');
  }
}

