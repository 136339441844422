import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';


import { HomeComponent } from '@components/home/home.component';
import { AboutComponent } from '@components/about/about.component';
import { CampaignListingComponent } from '@components/campaign-listing/campaign-listing.component';
import { CampaignDetailsComponent } from '@components/campaign-details/campaign-details.component';
import { ProvidersComponent } from '@components/providers/providers.component';
import { EventsDetailsComponent } from '@components/events-details/events-details.component';
import { BlogListingComponent } from '@components/blog-listing/blog-listing.component';
import { BlogDetailsComponent } from '@components/blog-details/blog-details.component';
import { ContactComponent } from '@components/contact/contact.component';


const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'home',
    redirectTo: '',
  },
  {
    path: 'about',
    component: AboutComponent,
  },
  {
    path: 'providers',
    component: ProvidersComponent,
  },
  {
    path: 'blogger',
    component: BlogListingComponent,
  },
  {
    path: 'blogger/:postId',
    component: BlogDetailsComponent,
  },
  {
    path: 'contact',
    component: ContactComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
