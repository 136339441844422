<section class="section-padding inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="text-center">{{'Providers.title' | translate}}</h1>
      </div>
    </div>
  </div>
</section>
<section class="section-padding-gray">
  <div class="container">
    <div class="row">
      <div class="col-lg-4 col-md-6 col-sm-6" *ngFor="let provider of providers|sortBy:'name':'asc'" (click)="navigateTo(provider)">
        <div class="event">
          <div class="img-cont">
            <img [src]="provider.imageUrl" class="img-fluid" alt="Post-1">
          </div>
          <hr class="solid">
          <div class="text-cont">
            <h5><a>{{provider.name}}</a> </h5>
            <p [innerHTML]="provider.shortDescription"></p>

            <a href="" class="read-more">{{'Providers.knowThem' | translate}} <i class="icofont-long-arrow-right"></i></a> </div>
        </div>
      </div>
    </div>
  </div>
</section>

<ngx-loading [show]="loading" [config]="{ animationType: ngxLoadingAnimationTypes.doubleBounce,
        backdropBackgroundColour: 'rgba(0,0,0,0.3)', backdropBorderRadius: '10px',
        primaryColour: '#1E3F5A', secondaryColour: '#E9B335', tertiaryColour: '#1E3F5A' }"></ngx-loading>
