import { Injectable } from '@angular/core';
import { Email } from '@shared/models/email.model';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EmailService {
  constructor(
    private http: HttpClient,
  ) { }

  sendEmail(email: Email) {
    return this.http.post(environment.Contact.sendEmail, email, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
}
