import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class ProvidersService {
  constructor(
    private firestore: AngularFirestore,
  ) { }

  listProviders() {
    return this.firestore.collection(`${environment.Collections.providers}`)
      .valueChanges();
      // .snapshotChanges()
      // .pipe(map(snapshot =>
    // snapshot.map(info => ({ uid: info.payload.doc.id, ...info.payload.doc.data() as any }))));
  }
}
