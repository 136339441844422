<section *ngIf="post" class="section-padding inner-header">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-12">
        <h1 class="text-center">{{post.title}}</h1>
      </div>
    </div>
  </div>
</section>
<section *ngIf="post" class="section-padding-gray blog-posts">
  <div class="container">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="col-md-12 p-0">
            <div class="post" >
              <div class="text-cont" [innerHTML]="post.content">

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<ngx-loading [show]="loading" [config]="{ animationType: ngxLoadingAnimationTypes.doubleBounce,
  backdropBackgroundColour: 'rgba(0,0,0,0.3)', backdropBorderRadius: '10px',
  primaryColour: '#1E3F5A', secondaryColour: '#E9B335', tertiaryColour: '#1E3F5A' }"></ngx-loading>