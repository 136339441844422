<section class="services-2 section-padding">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="section-title-2">
                    <h3 class="text-center" [innerHTML]="'Services.title' | titlecase | translate"></h3>
                    <p class="text-center" [innerHTML]="'Services.description' | translate"></p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="item"> <i class="icofont-badge"></i>
                    <h6>Excelencia</h6>
                    <p>Día a día nos esforzamos para dar el mejor servicio a los clientes que confían en nosotros</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="item"> <i class="icofont-ui-user-group"></i>
                    <h6>Cercanía</h6>
                    <p>Nuestro equipo se especializa en averiguar lo que cada cliente necesita a través de un trato totalmente personalizado y cercano.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="item"> <i class="icofont-light-bulb"></i>
                    <h6>Innovación</h6>
                    <p>Buscamos estar siempre un paso por delante tratando de tener las últimas novedades del mercado.</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="item"> <i class="icofont-handshake-deal"></i>
                    <h6>Experiencia</h6>
                    <p>Nuestros más de 20 años en el sector nos avalan como la mejor elección para su uniformidad laboral.</p>
                </div>
            </div>
        </div>
    </div>
</section>