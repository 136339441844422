import { Component, OnInit } from '@angular/core';
import { ClientCommentsService } from '@core/services/client-comments.service';
import { ClientComment } from '@shared/models/comments.model';
declare var $: any;

@Component({
  selector: 'app-client-comments',
  templateUrl: './client-comments.component.html',
  styleUrls: ['./client-comments.component.css'],
})
export class ClientCommentsComponent implements OnInit {

  reviews: ClientComment[] = [];
  commentsLoaded: boolean;


  constructor(
    private clientCommentService: ClientCommentsService,
  ) { }

  ngOnInit(): void {
    this.clientCommentService.loadClientComments()
    .subscribe((reviews) => {
      this.reviews = reviews;
      this.commentsLoaded = true;
      $.getScript('../assets/js/custom.js');
    }, () => {
      this.commentsLoaded = true;
    });
  }
}
