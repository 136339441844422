import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { ClientComment } from '@shared/models/comments.model';


@Injectable({
  providedIn: 'root',
})
export class ClientCommentsService {
  constructor(
    private http: HttpClient,
  ) { }

  loadClientComments(): Observable<ClientComment[]> {
    return this.http.get(environment.commentsUri) as Observable<ClientComment[]>;
  }
}
