/**
 * Used as representation of navigation link
 */
export class NavigationModel {
  readonly translateTitle: string;

  readonly link: string;


  constructor(translateTitle: string, link: string) {
    this.translateTitle = translateTitle;
    this.link = link;
  }
}
