import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BlogService {
  constructor(
    private http: HttpClient,
  ) { }

  private parseBlogResponse(occurrence) {
    const { id, title, content, _embedded, excerpt, date } = occurrence;
    const previewImage = _embedded['wp:featuredmedia'] && Array.isArray(_embedded['wp:featuredmedia'])
        ? _embedded['wp:featuredmedia'][0].source_url : 'assets/images/frontal.jpg';

    return {
      id,
      title: title.rendered,
      content: content.rendered,
      previewImage,
      contentPreview: excerpt.rendered,
      date,
    };
  }

  getPosts(postId?: number) {
    const uri = `${environment.Blog.POST_BASE_API}${ postId && !isNaN(postId) ? `/${postId}` : ''}?${environment.Blog.FIELDS}`;
    return this.http.get(uri).pipe(map((posts: any[] | any) => {
      if (!Array.isArray(posts)) {
        return this.parseBlogResponse(posts);
      }

      return posts.map(post => this.parseBlogResponse(post));
    }));
  }
}

