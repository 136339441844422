import { Component, OnInit } from '@angular/core';
import { BlogService } from '@core/services/blog.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { Post } from '@shared/models/post.model';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

@Component({
  selector: 'app-blog-listing',
  templateUrl: './blog-listing.component.html',
  styleUrls: ['./blog-listing.component.css'],
})
export class BlogListingComponent implements OnInit {
  posts: Post[] = [];
  page = 0;
  searchText = '';
  loading = false;
  ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(
    private blogService: BlogService,
    private translateService: TranslateService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    localStorage.removeItem(environment.Blog.storageKey);
    this.loading = true;
    this.blogService.getPosts().subscribe((data: Post[]) => {
      this.posts = data;
    }, () => Swal.fire({
      title: this.translateService.instant('Blog.results.fail.title'),
      text: this.translateService.instant('Blog.results.fail.message'),
      icon: 'error',
      confirmButtonText: this.translateService.instant('accept'),
    }), () => (this.loading = false));
  }

  onRowClick(post: Post) {
    localStorage.setItem(environment.Blog.storageKey, JSON.stringify(post));
    return this.router.navigate(['blogger/' + post.id]);
  }
}
