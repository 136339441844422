import { Component, OnInit } from '@angular/core';
import { PageNavigation } from '@shared/constants/navigation.constants';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css'],
})
export class TopbarComponent implements OnInit {
  readonly links = PageNavigation;
  constructor() { }

  ngOnInit(): void {
  }
}
